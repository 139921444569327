<template>
  <div>
        <panel
          :type="activeTab"
          @add="addHandle"
          @edit="editHandle"
           @copy="copyHandle"
          :shopOptions="shopOptions"
          ref="panelRefs"
        ></panel>
    <EditDialog
      v-model="dialogVisible"
      @refresh="refreshHandle"
      :editData="editData"
      :mode="editMode"
    ></EditDialog>
  </div>
</template>

<script>
import { CATEGORY_OPTIONS } from "@/helpers/constants";
import { getShopOptions } from "@/apis/auth";
import panel from "./panel";
import EditDialog from "./editDialog";
export default {
  components: { panel, EditDialog },
  data() {
    return {
      CATEGORY_OPTIONS: CATEGORY_OPTIONS,
      activeTab: "",
      dialogVisible: false,
      editData: {},
      editMode: "add",
      panelRefs: {},
      shopOptions:[],
    };
  },
  created() {
    this.activeTab = CATEGORY_OPTIONS.length ? CATEGORY_OPTIONS[0].value : "";
    getShopOptions(this.shopOptions);
  },
  activated() {
    this.refreshHandle();
  },
  methods: {
    setItemRef(el, key) {
      this.panelRefs[key] = el;
    },
    tabChangeHandle({ props }) {
      const { name } = props;
      this.activeTab = name;
      this.$nextTick(()=>{
        this.refreshHandle();
      });
    },
    addHandle() {
      this.editMode = "add";
      this.dialogVisible = true;
    },
    editHandle(row) {
      console.log("editHandle", row);
      this.editMode = "edit";
      this.editData = row;
      this.dialogVisible = true;
    },
    refreshHandle() {
      this.$refs.panelRefs.getList();
    },
    copyHandle(row) {
      console.log("copyHandle", row);
      this.editMode = "copy";
      this.editData = row;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
